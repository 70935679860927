import React from 'react';
import {
  Container,
  CheckContainer,
  Title,
  SubTitle,
  CheckShine,
  CheckRegion
} from './style';
import { Check } from '../../../material-icon';

export default () => (
  <Container>
    <CheckRegion>
      <CheckShine />
      <CheckContainer>
        <Check />
      </CheckContainer>
    </CheckRegion>
    <Title>We’ll be in touch!</Title>
    <SubTitle>
      If you want to contact us via email, you can do so at{' '}
      <a href="mailto:hello@bondspark.co.za">hello@bondspark.co.za</a>
    </SubTitle>
  </Container>
);
