import React, { useState, useCallback } from 'react';
import CenterContainer from '../center-container';
import {
  Container,
  HeroRegion,
  Hero,
  ContentRegion,
  Title,
  Description,
  CallToActionRegion,
  Outro,
  OutroContent,
  InputLabel,
  InputRegion,
  InputSubmit,
  InputFieldRegion
} from '../hero-bar';
import { ArrowForward } from '../material-icon';
import Scribble from '../scribble';
import HeroSrc from './hero.png';
import * as Input from '../input';
import valueOrEvent from '../../utilities/value-or-event';
import { usePhone } from '../../utilities/phone';
import ButtonLoader from '../button-loader';
import { useContactMe } from '../../api/contact-me';
import Success from './components/success';
import { Wrapper, ButtonArea } from './styles';

export default () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = usePhone('');
  const [email, setEmail] = useState('');

  const handleNameChange = useCallback(
    e => {
      setName(valueOrEvent(e));
    },
    [setName]
  );
  const handlePhoneChange = useCallback(
    e => {
      setPhone(valueOrEvent(e));
    },
    [setPhone]
  );
  const handleEmailChange = useCallback(
    e => {
      setEmail(valueOrEvent(e));
    },
    [setEmail]
  );
  const { contactMe, loading, success } = useContactMe();
  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      await contactMe({
        name,
        phone,
        email
      });
    },
    [name, phone, email]
  );

  if (success) return <Success />;

  return (
    <Wrapper>
      <CenterContainer>
        <Container>
          <HeroRegion>
            <Hero src={HeroSrc} alt="Hand with tin cup telephone" />
          </HeroRegion>
          <ContentRegion>
            <Title>We’re a social bunch, get in touch — let’s chat.</Title>
            <Description>
              Get a home loan specialist to call you back and answer any
              questions you still might have.
            </Description>
            <CallToActionRegion>
              <form onSubmit={handleSubmit}>
                <InputRegion>
                  <InputLabel>Full name:</InputLabel>
                  <InputFieldRegion>
                    <Input.Container>
                      <Input.Field
                        type="text"
                        placeholder="e.g. Themba Radebe"
                        value={name}
                        onChange={handleNameChange}
                        required
                      />
                    </Input.Container>
                  </InputFieldRegion>
                </InputRegion>
                <InputRegion>
                  <InputLabel>Phone Number:</InputLabel>
                  <InputFieldRegion>
                    <Input.Container>
                      <Input.Prefix>+27</Input.Prefix>
                      <Input.Field
                        required
                        type="tel"
                        value={phone}
                        placeholder="e.g. 79 234 2352"
                        onChange={handlePhoneChange}
                      />
                    </Input.Container>
                  </InputFieldRegion>
                </InputRegion>
                <InputRegion>
                  <InputLabel>Email:</InputLabel>
                  <InputFieldRegion>
                    <Input.Container>
                      <Input.Field
                        required
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="e.g. example@gmail.com"
                      />
                    </Input.Container>
                  </InputFieldRegion>
                </InputRegion>
                <ButtonArea>
                  <InputSubmit disabled={loading}>
                    Contact Me
                    {loading ? <ButtonLoader /> : <ArrowForward />}
                  </InputSubmit>
                </ButtonArea>
              </form>
            </CallToActionRegion>
            <Outro>
              <Scribble />
              <OutroContent>
                We respond within one business day, so keep an eye on your
                mobile. Or message us at{' '}
                <a href="mailto:hello@bondspark.co.za">hello@bondspark.co.za</a>
              </OutroContent>
            </Outro>
          </ContentRegion>
        </Container>
      </CenterContainer>
    </Wrapper>
  );
};
