import { useCallback, useState } from 'react';
import valueOrEvent from './value-or-event';

export default (initialValue, format, parse, afterChange = null) => {
  const [value, onChange] = useState(parse(initialValue));
  const parseOnChange = useCallback(
    e => {
      const value = valueOrEvent(e);
      const parsedValue = parse(value);
      onChange(parsedValue);
      if (afterChange) afterChange(parsedValue);
    },
    [onChange, afterChange]
  );
  const formattedValue = format(value);
  return [formattedValue, parseOnChange, value, onChange];
};
