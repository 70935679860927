import styled from '@emotion/styled/macro';
import { Candy, Midnight } from '../../styles/colours';

export const Container = styled.div`
  font-size: 15px;
  border: 1px solid #e2e8ed;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  margin: 0 auto;
  position: relative;
`;

export const Prefix = styled.div`
  border-right: 1px solid #d5dade;
  border-radius: 5px 0 0 5px;
  background-color: #ffffff;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  line-height: 2.1em;
  padding: 0 0.8em;
`;

export const Field = styled.input`
  flex-grow: 1;
  border: 0;
  font-size: inherit;
  font-weight: 400;
  padding: 0 1em;
  line-height: 2.5em;
  outline: none;
  cursor: pointer;
  color: ${Midnight};
  background: none;

  ::placeholder {
    color: #b3bfc9;
  }
`;

export const Button = styled.button`
  font-size: 1.5em;
  padding: 0 0.35em;
  background: ${Candy};
  color: #fff;
  text-align: center;
  border: 0;
  margin: -1px;
  border-radius: 0 5px 5px 0;
  outline: none;
  cursor: pointer;
`;

export const Hint = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 5px;
  color: #b3bfc9;
  font-size: 12px;
  line-height: 15px;
`;
