export default value => {
  if (!value && value !== 0) return '';
  let result = value;
  const isZeroPrefixed = value.startsWith('0');
  const firstSpaceIndex = isZeroPrefixed ? 4 : 3;
  const secondSpaceIndex = isZeroPrefixed ? 7 : 6;

  if (value.length >= firstSpaceIndex)
    result =
      result.substr(0, firstSpaceIndex - 1) +
      ' ' +
      result.substr(firstSpaceIndex - 1);

  if (value.length >= secondSpaceIndex)
    result =
      result.substr(0, secondSpaceIndex) +
      ' ' +
      result.substr(secondSpaceIndex);

  return result;
};
