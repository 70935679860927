import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { EaseOutCirc } from '../../styles/easing';

const Spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  animation: ${Spin} ${EaseOutCirc} 2s infinite;
  display: inline-block;
  position: absolute;
  height: 1.2em;
  width: 1.2em;
  right: 7px;
  top: 50%;
  margin-top: -0.6em;

  svg {
    height: 1.2em;
  }

  #website_about {
    fill: #fff;
  }
`;
