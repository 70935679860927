import styled from '@emotion/styled';
import backgroundUrl from './background.svg';
import { keyframes } from '@emotion/core';

const ShineAnimation = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Container = styled.div`
  background: url('${backgroundUrl}');
  background-size: contain;
  position: absolute;
  width: 600px;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${ShineAnimation} 30s linear infinite;
  z-index: 0;
`;
