export default value => {
  if (!value) return;

  const onlyNums = value.toString().replace(/[^\d]/g, '');

  if (!onlyNums) return;

  if (onlyNums.startsWith('0')) return onlyNums.substr(0, 10);

  return onlyNums.substr(0, 9);
};
