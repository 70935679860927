import { useCallback, useState } from 'react';
import useLogging from '../../utilities/use-logging';

export const useContactMe = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { error, info } = useLogging();
  const contactMe = useCallback(
    async model => {
      if (loading) return;
      setLoading(true);

      const res = await fetch('/api/contact-me?api-version=2.0', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(model)
      });

      if (res.ok)
        info('Contact me request sent', {
          statusText: res.statusText,
          model
        });
      else
        error('Contact me request failed', {
          statusText: res.statusText,
          model
        });

      setLoading(true);
      setSuccess(true);
    },
    [setLoading, setSuccess, loading]
  );
  return {
    contactMe,
    loading,
    success
  };
};
