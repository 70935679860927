import styled from '@emotion/styled';
import { Candy } from '../../../../styles/colours';
import ShineContainer from '../../../shine-container';
import { SM } from '../../../../styles/responsiveness';

export const Container = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 150px 0 100px 0;
  max-width: 100%;

  ${SM} {
    overflow: hidden;
  }
`;

export const CheckRegion = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 35px;
`;

export const CheckShine = styled(ShineContainer)`
  ${SM} {
    width: 400px;
    height: 400px;
  }
`;

export const CheckContainer = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #ffc0e4;
  position: relative;
  display: inline-block;
  color: ${Candy};
  line-height: 56px;
  font-size: 40px;
`;

export const Title = styled.div`
  font-family: 'Source Serif Pro', serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 30px;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-size: 12px;
  line-height: 15px;
  max-width: 236px;
  margin: 0 auto;
  position: relative;
  z-index: 10;

  a {
    color: ${Candy};
  }
`;
