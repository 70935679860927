import styled from '@emotion/styled';
import { U2 } from '../../styles/colours';
import { SM } from '../../styles/responsiveness';

export const Wrapper = styled.section`
  background: ${U2};
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 0 15px 0;
  max-width: 300px;

  ${SM} {
    margin: 0 auto;
  }
`;
