import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { SM } from '../../styles/responsiveness';
import { CandyInvertedButton } from '../button';
import { Container as MaterialIcon } from '../material-icon';

export const Container = styled.div`
  display: flex;
  padding: 50px 0;
  align-items: center;

  ${SM} {
    margin: 0 auto;
    max-width: 400px;
    display: block;
    text-align: center;
  }
`;

export const HeroRegion = styled.div`
  width: 50%;
  ${SM} {
    width: 100%;
    padding-bottom: 50px;
    max-width: 250px;
    margin: 0 auto;
  }
`;

export const Hero = styled.img`
  width: 100%;
`;

export const ContentRegion = styled.div`
  width: 50%;
  margin-left: 60px;

  ${SM} {
    width: 100%;
    margin-left: 0;
  }
`;

export const PreTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  font-family: 'Source Serif Pro', serif;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 30px;
  max-width: 400px;
  margin-bottom: 30px;

  ${SM} {
    font-size: 22px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
  max-width: 380px;

  em {
    font-style: normal;
    text-decoration: underline;
  }

  ${SM} {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const CallToActionRegion = styled.div`
  padding: 30px 0;
`;

export const Outro = styled.div`
  display: flex;
  align-items: center;

  ${SM} {
    justify-content: center;
  }
`;

export const OutroContent = styled.div`
  font-size: 12px;
  line-height: 15px;
  max-width: 280px;
  margin-left: 15px;
  text-align: left;

  em {
    font-style: normal;
    text-decoration: underline;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const InputLabel = styled.div`
  color: #718594;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;

export const InputRegion = styled.div`
  padding: 5px 0 15px 0;
  max-width: 300px;

  ${SM} {
    margin: 0 auto;
  }
`;

const InputSubmitDisabled = css`
  box-shadow: none;
  opacity: 0.5;
  cursor: default;

  &:hover {
    transform: none;
    box-shadow: none;
  }
`;

export const InputSubmit = styled(CandyInvertedButton.withComponent('button'))`
  cursor: pointer;
  margin-top: 20px;
  outline: none;

  ${({ disabled }) => disabled && InputSubmitDisabled}
`;

export const RoundSubmitButton = styled(
  CandyInvertedButton.withComponent('button')
)`
  padding: 0;
  cursor: pointer;
  margin-top: 20px;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  ${MaterialIcon} {
    position: relative;
    right: 0;
    top: 0;
    font-size: 30px;
    transform: none;
    margin-top: 0;
    display: flex;

    .material-icons {
      color: inherit;
      top: 0;
      font-size: 30px;
      line-height: none;
      position: relative;
    }
  }

  ${({ disabled }) => disabled && InputSubmitDisabled}
`;

export const InputFieldRegion = styled.div`
  padding-top: 5px;
`;
